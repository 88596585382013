import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import closeicon from '../../images/closeicon.png'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ScheduleDemo = ({
    is_active,
    onClose,
    onSubmit,
    onChange,
    phoneNumberSet,
    submitting,
    first_name,
    last_name,
    email,
    phone,
    description,
    designations,
    employee_count,
    selected_designation,
    selected_employee_count
  }) => {
return (
    <div className={classnames('modal', {"is-active" : is_active})}>
        <div onClick={onClose} className="modal-background"></div>
        <div className="modal-card wider-card schedule-demo-card has-text-left overflow-auto">
            <form onSubmit={onSubmit} className="contact-form">
                <section className="modal-card-body no-overflow is-paddingless radius-10">
                    <div className="columns pd-12">
                        <div className="column is-5 is-hidden-mobile no-pd-bottom bg-amber-contact">
                        </div>
                        <div className="column pd-form-contact">
                            <h2 className="has-text-centered has-text-dark has-text-weight-bold is-size-4">Request a Demo</h2>
                            <button onClick={onClose} type="button" className="button is-white close-btn" aria-label="close"><img src={closeicon} width="12px" alt="close"/></button>
                            <br/>
                            <div className="columns no-margin-bottom">
                                <div className="field column no-pd-bottom no-margin-bottom">
                                    <label className="label is-size-7">First Name*</label>
                                    <div className="control">
                                        <input className="input schedule-demo-input-field" type="text" name="first_name" value={first_name} onChange={onChange} autoFocus required/>
                                    </div>
                                </div>
                                <div className="field column no-pd-bottom mg-10-bottom">
                                    <label className="label is-size-7">Last Name</label>
                                    <div className="control">
                                        <input className="input schedule-demo-input-field" type="text" name="last_name" value={last_name} onChange={onChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label is-size-7">Work Email*</label>
                                <div className="control">
                                    <input className="input schedule-demo-input-field" type="email" name="email" value={email} onChange={onChange} required/>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label is-size-7">Phone Number*</label>
                                <div className="control">
                                <PhoneInput
                                inputProps={{name: 'phone',required: true,autoFocus: true}}
                                country={'in'}
                                inputClass= 'input schedule-demo-input-field schedule-demo-phone-field'
                                buttonClass = 'schedule-demo-flag-field'
                                value={(phone ? phone : '')}
                                onChange={phoneNumberSet}/>
                                </div>
                            </div>
                            <div className="columns no-margin-bottom pd-top-bottom-10-15">
                                <div className="field column no-pd-bottom   mg-10-bottom  is-flex schedule-demo-input-field">
                                    <div className="select is-small">
                                        <select name="selected_designation" className="font-12 schedule-demo-input-field" value={selected_designation} onChange={onChange} required>
                                            <option key="select">Select Designation</option>
                                            {designations.map((designation, index) => (
                                                <option key={index}>{designation}</option>
                                            )) 
                                            }
                                        </select>
                                    </div>
                                    <label className="label is-size-7 mg-left-5">*</label>
                                </div>
                                <div className="field column no-pd-bottom mg-10-bottom is-flex schedule-demo-input-field">
                                    <div className="select is-small">
                                        <select name="selected_employee_count" className="font-12 schedule-demo-input-field" value={selected_employee_count} onChange={onChange} required>
                                            <option key="select">Select Number of Employees</option>
                                            {employee_count.map((count, index) => (
                                                <option key={index}>{count}</option>
                                            )) 
                                            }
                                        </select>
                                    </div>
                                    <label className="label is-size-7 mg-left-5">*</label>
                                </div>
                            </div>
                            <div className="field">                                
                            <label className="label is-size-7">Where did you hear about us?</label>
                               <div className="control">
                                   <input className="input schedule-demo-input-field"  type="text" value={description} name="description" onChange={onChange}/>
                                </div>
                            </div>
                            <label className="checkbox font-12">
                                <input type="checkbox"/>
                                &nbsp; <span className="font-12">Send me occasional emails about employee engagement trends</span>
                            </label>
                            <div className="mg-top-10 pd-top-12">
                                <button type="submit" className={classnames('button is-blue nav-button is-fullwidth demo-submit-tracking', {'is-loading': submitting})}>Submit</button>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    </div>
);
};

// Porptypes
// ---------
ScheduleDemo.propTypes = {
    is_active: PropTypes.bool.isRequired,
    phoneNumberSet: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    designations: PropTypes.array,
    employee_count: PropTypes.array,
    selected_designation: PropTypes.string,
    selected_employee_count: PropTypes.string
};

// default props
// -------------
ScheduleDemo.defaultProps = {
    is_active: false,
};

export default ScheduleDemo;

