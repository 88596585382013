import React, { Component } from "react"
import { Link } from "gatsby"
import case_study_clipboard from "../images/case-study-clipboard.png"
import reports_clipboard from "../images/reports-clipboard.png"
import events_clipboard from "../images/events-clipboard.png"
import roi_clipboard from "../images/roi-clipboard.png"
import newsroom_clipboard from "../images/newsroom-clipboard.png"
import career_clipboard from "../images/career-clipboard.png"
import customers_clipboard from "../images/customers-clipboard.png"
import pricing_clipboard from "../images/pricing-clipboard.png"

export default class Header extends Component {
  state = {
    tabs: {
      "Resources": [
        {
          title: "Events",
          description: "Join thousands of people leaders who participate inFeedo's latest webinars, events and workshops",
          link: "/events",
          img: events_clipboard,
        },
        {
          title: "Case Studies",
          description: "Know why 100+ CHROs use Amber to build high-performance cultures",
          link: "/resources?type=caseStudies",
          img: case_study_clipboard,
        },
        {
          title: "Reports & Guides",
          description: "Download our industry-leading reports that deep-dive into the data behind all things people management",
          link: "/resources?type=reports",
          img: reports_clipboard,
        },
        {
          title: "ROI Calculator",
          description: "Demystify the ROI of employee engagement in 5 clicks",
          link: "https://resources.infeedo.com/roi-calculator",
          img: roi_clipboard,
          open_new_page: true
        },
      ],
      "About Us": [
        {
          title: "Customers",
          description: "Stories from People Leaders Taking Engagement to the Next Level",
          link: "/customers",
          img: customers_clipboard,
        },
        {
          title: "Careers",
          description: "We're hiring! Come, revolutionize employee engagement with us",
          link: "https://jobs.infeedo.com/",
          img: career_clipboard,
          open_new_page: true,
        },
        {
          title: "Newsroom",
          description: "As Amber travels the world, catch all the action here. Be a part of our ever-growing community",
          link: "/newsroom",
          img: newsroom_clipboard,
        },
        {
          title: "Product Pricing",
          description: "Companies small and large use Amber. No matter what your needs, we have the plan for you",
          link: "/pricing",
          img: pricing_clipboard,
        },
      ],
    },
  }

  render() {
    const { tabs } = this.state
    return (
      <div className="navbar-start is-hidden-touch">
        {Object.entries(tabs).map(([dropdown_trigger, sub_menu], index) => (
          <div
            className="dropdown is-hoverable navbar-item reduce-opacity-on-hover "
            id="header-menu"
          >
            <div className="dropdown-trigger ">
              <div>
                <span className="">{dropdown_trigger}</span>
                <i className="menu-arrow"></i>
              </div>
            </div>
            <div
              className="dropdown-menu navbar-dropdown-fixed no-padding-top"
              id="dropdown-menu4"
              role="menu"
            >
              <div className="dropdown-content is-radiusless navbar-dropdown-shadow">
                <div className="width-90-aligned-center">
                  <div className="columns pd-top-bottom-15">
                    {sub_menu.map((data, index) => (
                      <div className="column is-3 active-dropdown-card">
                        {!data.open_new_page ? (
                          <Link
                            to={data.link}
                            className="is-flex"
                            target="_blank"
                          >
                            <div className="mg-right-10 ">
                              <img
                                src={data.img}
                                alt="infeedo"
                                className="navbar-dropdown-image"
                              />
                            </div>
                            <div>
                              <div className="navbar-dropdown-heading">
                                {data.title}
                              </div>
                              <div className="navbar-dropdown-description">
                                {data.description}
                              </div>
                              <div className="navbar-dropdown-link">
                                Learn More &#8250;
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <a
                            href={data.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="is-flex"
                          >
                            <div className="mg-right-10 ">
                              <img
                                src={data.img}
                                alt="infeedo"
                                className="navbar-dropdown-image"
                              />
                            </div>
                            <div>
                              <div className="navbar-dropdown-heading">
                                {data.title}
                              </div>
                              <div className="navbar-dropdown-description">
                                {data.description}
                              </div>
                              <div className="navbar-dropdown-link">
                                Learn More &#8250;
                              </div>
                            </div>
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <a
          href="https://blog.infeedo.com"
          target="_blank"
          rel="noopener noreferrer"
          className="navbar-item"
        >
          Blog
        </a>
      </div>
    )
  }
}
