export const email_providers = [
    'gmail.com',
    'gmx.at',
    'gmx.com',
    'gmx.de',
    'gmx.fr',
    'gmx.li',
    'gmx.net',
    'yaho.com',
    'yahoo.ca',
    'yahoo.co.id',
    'yahoo.co.in',
    'yahoo.co.jp',
    'yahoo.co.kr',
    'yahoo.co.nz',
    'yahoo.co.uk',
    'yahoo.com',
    'yahoo.com.ar',
    'yahoo.com.au',
    'yahoo.com.br',
    'yahoo.com.cn',
    'yahoo.com.hk',
    'yahoo.com.is',
    'yahoo.com.mx',
    'yahoo.com.ph',
    'yahoo.com.ru',
    'yahoo.com.sg',
    'yahoo.de',
    'yahoo.dk',
    'yahoo.es',
    'yahoo.fr',
    'yahoo.ie',
    'yahoo.in',
    'yahoo.it',
    'yahoo.jp',
    'yahoo.ru',
    'yahoo.se',
    'yahoofs.com',
    'outlook.com',
    'outlook.com.br',
    'zoho.com',
    'aol.com',
    'aol.it',
    'prontomail.com',
    'protonmail.com',
    'hotmail.be',
    'hotmail.co.il',
    'hotmail.co.uk',
    'hotmail.com',
    'hotmail.com.ar',
    'hotmail.com.br',
    'hotmail.com.mx',
    'hotmail.de',
    'hotmail.es',
    'hotmail.fr',
    'hotmail.it',
    'hotmail.kg',
    'hotmail.kz',
    'hotmail.ru',
    'mail-awu.de',
    'mail-box.cz',
    'mail-center.com',
    'mail-central.com',
    'mail-page.com',
    'mail.austria.com',
    'mail.az',
    'mail.be',
    'mail.bulgaria.com',
    'mail.byte.it',
    'mail.co.za',
    'mail.com',
    'mail.ee',
    'mail.entrepeneurmag.com',
    'mail.freetown.com',
    'mail.gr',
    'mail.hitthebeach.com',
    'mail.kmsp.com',
    'mail.md',
    'mail.nu',
    'mail.org.uk',
    'mail.pf',
    'mail.pharmacy.com',
    'mail.pt',
    'mail.r-o-o-t.com',
    'mail.ru',
    'mail.salu.net',
    'mail.sisna.com',
    'mail.spaceports.com',
    'mail.theboys.com',
    'mail.usa.com',
    'mail.vasarhely.hu',
]

export default email_providers;