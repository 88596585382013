import React, { Component } from 'react';
import infeedo from '../images/infeedo.svg';
import classnames from 'classnames';
import { Link } from "gatsby";
import config from '../../config';
import {looseFocus} from '../helper/LooseFocus';
import ScheduleDemoButton from './scheduledemo/ScheduleDemoButton';
import { globalHistory } from "@reach/router";
import queryString from 'query-string';
import money_bag from '../../src/images/money-bag-emoji.png'
import DropdownMenu from './header-dropdown-menu'
//import Countdown from 'react-countdown-now';

export default class Header extends Component {

  state = {
    is_active: false,
    event_added: false,
    current_path: '',
    trigger_tour: false,
    open_pink_banner: false
  }

  
  
  componentDidMount(){
    this.getActivePath();
    if(window.location.pathname === '/') {
      if(localStorage.token) {
        window.location = `${config.beta_dashboard_url}/${localStorage.token}`
      }
    }
     this.setBannerStatus();
    const parsed = queryString.parse(window.location.search);
    if(parsed.open_form === "true"){
        this.setState({trigger_tour: true});
    }
  }

  setBannerStatus = () => {
    if(sessionStorage.open_pink_banner || window.location.pathname.includes('/roi-calculator')){
      this.setState({
        open_pink_banner: false
      })
    }
    else{
      this.setState({
        open_pink_banner: true
      })
    }
  }

  getActivePath = () => {
    const current_path = globalHistory.location.pathname;
    this.setState({current_path});
  }

  toggleNav = () => {
    const { is_active } = this.state;
    if(is_active){
      this.closeNav();
    }
    looseFocus();
    this.setState({is_active: true}, () => this.addEventListener());
  }

  addEventListener = () => {
    const { is_active } = this.state;
    if(is_active){
      document.body.addEventListener("click", this.closeNav);
      this.setState({event_added: true});
    } else {
      document.body.removeEventListener('click', this.closeNav);
      this.setState({event_added: false});
    }
  }

  openNav = () => {
    this.setState({is_active: true});
  }

  closeNav = () => {
    this.setState({is_active: false});
  }

  closePinkBanner = () =>{
    sessionStorage.setItem('open_pink_banner', true);
    this.setBannerStatus();
  }
 
  //code to render a countdown using Countdown library
  rendererDays = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span></span>;
    } else if (days<=1){
      // Render a countdown\
      return <span><b>{days} day</b></span>
    }
    else{
      return <span> <b>{days} days</b></span>
    }
  };

  render() {
    const { is_active, current_path, trigger_tour, open_pink_banner } = this.state;
    return (
      <header>
        <nav className="navbar is-fixed-top nav-padding" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img src={infeedo} width="112" height="28" className="navbar-logo" alt="infeedo"/>
            </Link>
            <div className="minimal-header">
            <button  onClick={this.toggleNav} className={classnames('navbar-burger no-mar-left no-border burger' ,{'is-active': is_active})} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
            <ScheduleDemoButton is_hidden_desktop = {true}   trigger_tour={trigger_tour}/>
            </div>
            {is_active ? <div className="placeholder-btn"></div> : null}
          </div>

          <div id="navbarBasicExample" className={classnames('navbar-menu',{'is-active': is_active})}>
          {/* <DropdownMenu/> */}
            <div className="navbar-start ">
              <Link to="/customers" className={classnames('navbar-item', {'is-active': current_path.includes('customers')})}>
                Customers
              </Link>

              <Link to="/pricing" className={classnames('navbar-item', {'is-active': current_path.includes('pricing')})}>
                Pricing
              </Link>

              <Link to="/newsroom" className={classnames('navbar-item', {'is-active': current_path.includes('newsroom')})}>
                Newsroom
              </Link>
              
              <Link to="/resources" className={classnames('navbar-item', {'is-active': current_path.includes('resources')})}>
                Resources
              </Link>

              <Link to="/events" className={classnames('navbar-item', {'is-active': current_path.includes('events')})}>
                Events 
              </Link>

              <a href="https://blog.infeedo.com" target="_blank" rel="noopener noreferrer" className="navbar-item">
                Blog
              </a>

              <a href="https://jobs.infeedo.com" target="_blank" rel="noopener noreferrer" className="navbar-item">
                Careers
              </a>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <a href={`${config.home_url}/login/`} target="_blank" rel="noopener noreferrer" className="button is-link is-outlined nav-button is-fullwidth is-hidden-tablet">
                    Login
                  </a>
                  <a href={`${config.home_url}/login/`} target="_blank" rel="noopener noreferrer" className="button is-white nav-button is-hidden-mobile">
                    Login
                  </a>
                  {/* <ScheduleDemoButton is_fullwidth={true} is_hidden_tablet={true} classes="is-fullwidth is-hidden-tablet"/> */}
                  <ScheduleDemoButton is_hidden_mobile={true} trigger_tour={trigger_tour}/>
                </div>
              </div>
            </div>
          </div>
        </nav>
        { open_pink_banner ? 
            <nav className="navbar is-fixed-top navbanner-padding banner-navbar"  role="navigation" aria-label="main navigation" id="TopPinkBanner">
             <div className="initial-div">
              {/* <img src={money_bag} className="banner-img is-hidden-mobile" alt="infeedo logo white"></img> */}
              <p className="navbar-text is-size-8-mobile "> &#127881;  Introducing Amber Custom Checkins. Custom Checkins are just like <b>Pulse Surveys, but more human</b> &#128578;</p>
              <button className="button SeeNow-button is-size-8-mobile is-size-6" onClick = {event =>  window.open('https://getstarted.infeedo.com/custom-check-in-amber')}>Get early access</button>
              <div className="icon is-large is-hidden-desktop cross-icon  ">
                <svg onClick={this.closePinkBanner} className="popup-modal-close  mg-right-5 " xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
              </div>
             </div>
             <div className="icon is-large is-hidden-touch  cross-icon">
              <svg onClick={this.closePinkBanner} className="popup-modal-close   mg-right-10" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
             </div>
            </nav> : null}
    </header>
    )
  }
}
