import axios from '../helper/Axios';
import md5 from 'md5';
import handleException from './errorHandling';
import config from '../../config';

export const getServiceRegion = async (email, open_url) => {
    try {
        let hashEmail = md5(email);
        const regionResponse = await axios.get(`/v1/service_region/${hashEmail}?module_name=${open_url}`);
        if(regionResponse.status === 200 && regionResponse.data){
            if(regionResponse.data.region !== config.default_region){
                if(sessionStorage.current_region_url){
                    sessionStorage.current_region_url = regionResponse.data.base_url;
                  }
                  else{
                      sessionStorage.setItem('current_region_url', regionResponse.data.base_url);
                  }
            }
        } 
        return regionResponse;
    } catch (error) {
        handleException(error);
    }
}

export const getBaseURL = async (region, open_url) => {
    try {
        const response = await axios.get(`/v1/service_region/base_url/${region}/${open_url}`);
        if(response.status === 200 && response.data){
            if(response.data.region !== config.default_region){
                if(sessionStorage.current_region_url){
                sessionStorage.current_region_url = response.data.base_url;
                }
                else{
                    sessionStorage.setItem('current_region_url', response.data.base_url);
                }
            }
        }
        return response;

    } catch (error) {
        handleException(error);
    }
}

export const resetSessionStorage = () => {
    if(sessionStorage.current_region_url){
        sessionStorage.removeItem("current_region_url");
    }
}