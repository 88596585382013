import React from "react"
import { Link } from "gatsby"
import classnames from 'classnames';
import PropTypes from 'prop-types';
import infeedo_white from '../images/infeedo-white.png';
// import soc2 from '../images/soc2.png';
import soc from '../images/soc.png';
import ccpa from '../images/ccpa.png';
import lgpd from '../images/lgpd.png';
// import iso from '../images/iso.png';
import twitter from '../images/twitter.png';
import instagram from '../images/instagram.png';
import linkedin from '../images/linkedin.png';
import youtube from '../images/youtube.png';
import gdpr from '../images/gdpr.png';
import iso_iec from '../images/ISO-IEC.png';

const footer_sections = [
    {
        title: 'Company',
        links: [
            {
                name: 'Careers',
                link: 'https://jobs.infeedo.com/',
                is_visible: true,
                link_flag: false,
                new_flag: false
            },
            {
                name: 'Partnership',
                link: '/partners',
                is_visible: true,
                link_flag: true,
                new_flag: true
            },
            {
                name: 'Awards',
                link: '/newsroom#NewsroomAwards',
                is_visible: true,
                link_flag: true,
                new_flag: false
            },
            {
                name: 'Founder',
                link: '/newsroom#FounderVideos',
                is_visible: true,
                link_flag: true,
                new_flag: false
            }
        ]
    },
    {
        title: 'Pricing',
        links: [
            {
                name: 'Plans',
                link: '/pricing',
                is_visible: true,
                link_flag: true,
                new_flag: false
            }
        ]
    },
    {
        title: 'Customers',
        links: [
            {
                name: 'Our Customers',
                link: '/customers#customers',
                is_visible: true,
                link_flag: true,
                new_flag: false
            },
            {
                name: 'Success Stories',
                link: '/customers#testimonials',
                is_visible: true,
                link_flag: true,
                new_flag: false
            },
        ]
    },
    {
        title: 'Resources',
        links: [
            {
                name: 'Blog',
                link: 'https://blog.infeedo.com/',
                is_visible: true,
                link_flag: false,
                new_flag: false
            },
            {
                name: 'Newsroom',
                link: '/newsroom',
                is_visible: true,
                link_flag: true,
                new_flag: false
            },
            {
                name: 'Reports & Case Studies',
                link: '/resources',
                is_visible: true,
                link_flag: true,
                new_flag: false
            },
            {
                 name: 'ROI Calculator',
                 link: 'https://resources.infeedo.com/roi-calculator',
                 is_visible: true,
                 link_flag: false,
                 new_flag: true
            }
        ]
    },
]

const Footer = ({mode, sticky_footer}) => (
    <footer className={classnames('footer footer-padding', {'footer-blue': !mode, 'footer-white': mode})}>
        {mode ? 
        // footer for login / forgot password pages
        <div className="columns pd-top-30">
            <div className="content has-text-centered column is-12">
                <p className="is-hidden-tablet has-text-centered is-size-7 pd-bottom-40">
                    <Link to="/" className="anchor-blue mg-left-right-10">Home</Link>
                    <a href="https://updates.infeedo.com/" target="_blank" rel="noopener noreferrer" className="anchor-blue mg-left-right-10">Product Updates</a>
                    <a href="https://help.infeedo.com/docs" target="_blank" rel="noopener noreferrer" className="anchor-blue mg-left-right-10">Help Centre</a>
                    <Link to="/legal" className="anchor-blue mg-left-right-10">Legal</Link><br/><br/>
                    <Link to="/website-terms" className="anchor-blue mg-left-right-10">Website Terms</Link>
                    <Link to="/privacy-policy" className="anchor-blue mg-left-right-10">Privacy</Link>
                    <Link to="/terms-and-conditions" className="anchor-blue mg-left-right-10">Product T&C</Link>
                </p>
                <p className={classnames('is-hidden-mobile', {'footer-two-fixed': sticky_footer})}>
                    <Link to="/" className="anchor-blue mg-left-right-10">Home</Link> .
                    <a href="https://updates.infeedo.com/" target="_blank" rel="noopener noreferrer" className="anchor-blue mg-left-right-10">Product Updates</a> .
                    <a href="https://help.infeedo.com/docs" target="_blank" rel="noopener noreferrer" className="anchor-blue mg-left-right-10">Help Centre</a> .
                    <Link to="/legal" className="anchor-blue mg-left-right-10">Legal</Link> .
                    <Link to="/website-terms" className="anchor-blue mg-left-right-10">Website Terms</Link> .
                    <Link to="/privacy-policy" className="anchor-blue mg-left-right-10">Privacy</Link> .
                    <Link to="/terms-and-conditions" className="anchor-blue mg-left-right-10">Product T&C</Link>
                </p>
            </div>
        </div>
        :
        // Footer for main website pages
        <div className="columns column is-11 is-paddingless mar-auto flex-wrap">
            <div className="content has-text-left column is-2 pd-left-mobile-24">
                <img src={infeedo_white} width="100px" alt="infeedo logo white"/>
            </div>
            <div className="content has-text-left column">
                <div className="columns mar-auto">
                {/* <div className="column is-1"></div> */}
                {footer_sections.map((section, index) => (
                    <div className="column is-2-5" key={index}>
                        <h5 className="footer-heading">{section.title}</h5>
                        {section.links.map((link, index) => (
                            <p key={index}>
                                {link.link_flag ? <Link to={link.link} className="anchor-white footer-anchor">{link.name}</Link> 
                                : <a href={link.link} className="anchor-white footer-anchor" target="_blank" rel="noopener noreferrer">
                                    {link.name}
                                </a> }
                                {link.new_flag ? <span className="tag new-icon">New</span> : null}
                            </p>
                        ))}
                    </div>
                ))}
                </div>
            </div>
            <div className="content has-text-right column is-2-desktop is-3-tablet">
                <div className="is-hidden-tablet has-text-left mg-bottom-50">
                        <span className="pd-10 middle"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={gdpr} width="80px" alt="gdpr"/></a></span>
                        <span className="pd-10 middle"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={ccpa} width="40px" alt="ccpa"/></a></span>
                        <span className="pd-10 middle"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={lgpd} width="80px" alt="lgpd"/></a></span>
                        {/* <span className="pd-10 super"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={iso} width="80px" alt="iso"/></a></span> */}
                        {/* <span className="pd-10"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={soc2} width="50px" alt="soc2"/></a></span> */}
                        <br></br>
                        <span className="pd-10 middle"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={soc} width="45px" alt="soc"/></a></span>
                        <span className="pd-10 middle"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={iso_iec} width="70px" alt="soc"/></a></span>
                </div>
                <div className="has-text-right is-hidden-mobile pd-left-12-mobile">
                    <p className="mg-bottom-10">&copy; {new Date().getFullYear()} inFeedo Tech Inc.<br/></p>
                    <p>
                    99 Wall Street #752<br/>
                    New York, NY 10005<br/>
                    USA
                    <br/><br/>
                    Contact Us
                    <br/>
                    <a href="mailto:help@infeedo.com" className="anchor-white">help@infeedo.com</a><br/>
                    {/* <a href="tel:+19295296133" className="anchor-white">+1 929-529-6133</a> */}
                    </p>
                </div>
                <div className="has-text-left is-hidden-tablet pd-left-12-mobile">
                    <p className="mg-bottom-10">&copy; {new Date().getFullYear()} inFeedo Tech Inc.<br/></p>
                    <p>
                    99 Wall Street #752<br/>
                    New York, NY 10005<br/>
                    USA
                    <br/><br/>
                    Contact Us
                    <br/>
                    <a href="mailto:help@infeedo.com" className="anchor-white">help@infeedo.com</a><br/>
                    {/* <a href="tel:+19295296133" className="anchor-white">+1 929-529-6133</a> */}
                    </p>
                </div>
            </div>
            <div className="content has-text-left column felx-wrap is-12 no-margin-padding-bottom">
                <div className="columns">
                    <div className="column is-8 is-hidden-mobile">
                        <span className="pd-15 no-pd-left"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={gdpr} width="130px" alt="gdpr"/></a></span>
                        <span className="pd-15"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={ccpa} width="50px" alt="ccpa"/></a></span>
                        <span className="pd-15 super"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={lgpd} width="110px" alt="lgpd"/></a></span>
                        <span className="pd-15"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={soc} width="55px" alt="soc"/></a></span>
                        <span className="pd-15"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={iso_iec} width="100px" alt="soc"/></a></span>
                        {/* <span className="pd-10"><a href="https://help.infeedo.com/docs/security" target="_blank" rel="noopener noreferrer"><img src={soc2} width="60px" alt="soc2"/></a></span> */}
                    </div>
                    <div className="column is-4 has-text-right-tablet">
                            <span className="pd-10"><a href="https://www.linkedin.com/company/infeedo" target="_blank" rel="noopener noreferrer"><img src={linkedin} className="pd-top-15" width="25px" alt="linkedin"/></a></span>
                            <span className="pd-10"><a href="https://www.youtube.com/channel/UCGOPlzzyE3QOog6WlkUdAYg/" target="_blank" rel="noopener noreferrer"><img src={youtube} className="pd-top-15" width="26px" alt="youtube"/></a></span>
                            <span className="pd-10"><a href="https://www.instagram.com/infeedo" target="_blank" rel="noopener noreferrer"><img src={instagram} className="pd-top-15" width="24px" alt="instagram"/></a></span>
                            <span className="pd-10 no-pd-right"><a href="https://twitter.com/inFeedo" target="_blank" rel="noopener noreferrer"><img src={twitter} className="pd-top-15" width="25px" alt="twitter"/></a></span>
                    </div>
                </div>
                <hr className="footer-line"/>
            </div>
            <div className="content has-text-left column is-12 no-padding-top">
                <p className="is-hidden-tablet has-text-left pd-bottom-40 mg-mobile-footer pd-left-12-mobile line-height-20">
                    <Link to="/legal" className="anchor-white mg-left-right-10 mobile-footer">Legal</Link><br/>
                    <Link to="/website-terms" className="anchor-white mg-left-right-10 mobile-footer">Website Terms</Link><br/>
                    <Link to="/privacy-policy" className="anchor-white mg-left-right-10 mobile-footer">Privacy</Link><br/>
                    <Link to="/data-protection" className="anchor-white mg-left-right-10">Data Protection</Link> <br/>
                    <Link to="/gdpr" className="anchor-white mg-left-right-10">GDPR</Link> <br/>
                    <Link to="/terms-and-conditions" className="anchor-white mg-left-right-10 mobile-footer">Product T&C</Link>
                </p>
                <p className="is-hidden-mobile">
                    <Link to="/legal" className="anchor-white mg-left-right-10">Legal</Link> |
                    <Link to="/website-terms" className="anchor-white mg-left-right-10">Website Terms</Link> |
                    <Link to="/privacy-policy" className="anchor-white mg-left-right-10">Privacy</Link> |
                    <Link to="/data-protection" className="anchor-white mg-left-right-10">DPA</Link> |
                    <Link to="/gdpr" className="anchor-white mg-left-right-10">GDPR</Link> |
                    <Link to="/terms-and-conditions" className="anchor-white mg-left-right-10">Product T&C</Link>
                </p>
            </div>
        </div>}
    </footer>

)

// Porptypes
// ---------
Footer.propTypes = {
    mode: PropTypes.bool.isRequired,
    sticky_footer: PropTypes.bool.isRequired
};

// default props
// -------------
Footer.defaultProps = {
    mode: false,
    sticky_footer: true
};

export default Footer