const env = {
  dev: {
    "env": "dev",
    "url": "https://api.dev.amber.infeedo.com/",
    "dashboard_url": "http://app.dev.amber.infeedo.com",
    "beta_dashboard_url": "http://beta.dev.amber.infeedo.com",
    "manager_dashboard": "http://manager.dev.amber.infeedo.com",
    "chat_url" : "http://chat.dev.amber.infeedo.com",
    "home_url": "http://dev.amber.infeedo.com",
    "exit_url": "",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  local: {
    "env": "local",
    "url": "http://54.89.104.23:9999/",
    "dashboard_url": "http://localhost:3000",
    "beta_dashboard_url": "http://localhost:3000",
    "manager_dashboard": "http://localhost:3000",
    "chat_url" : "https://localhost:3000",
    "home_url": "http://localhost:8000",
    "exit_url": "",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  crash: {
    "env": "crash",
    "url": "https://api.crash.amber.infeedo.com/",
    "dashboard_url": "http://app.crash.amber.infeedo.com",
    "beta_dashboard_url": "http://beta.crash.amber.infeedo.com",
    "manager_dashboard": "http://manager.crash.amber.infeedo.com",
    "chat_url" : "http://chat.crash.amber.infeedo.com",
    "home_url": "https://crash.amber.infeedo.com",
    "exit_url": "",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  dev2: {
    "env": "dev2",
    "url": "https://api.dev2.amber.infeedo.com/",
    "dashboard_url": "https://app.dev2.amber.infeedo.com",
    "beta_dashboard_url": "https://beta.dev2.amber.infeedo.com",
    "manager_dashboard": "https://manager.dev2.amber.infeedo.com",
    "chat_url" : "https://chat.dev2.amber.infeedo.com",
    "home_url": "https://dev2.amber.infeedo.com",
    "exit_url": "",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  stage: {
    "env": "stage",
    "url": "https://api.amber.fidento.com/",
    "dashboard_url": "http://app.amber.fidento.com",
    "beta_dashboard_url": "http://beta.amber.fidento.com",
    "manager_dashboard": "http://manager.amber.fidento.com",
    "chat_url" : "http://chat.amber.fidento.com",
    "home_url": "http://amber.fidento.com",
    "exit_url": "",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  uat: {
    "env": "uat",
    "url": "https://api.uat.amber.infeedo.com/",
    "dashboard_url": "http://app.uat.amber.infeedo.com",
    "beta_dashboard_url": "http://beta.uat.amber.infeedo.com",
    "chat_url" : "http://chat.uat.amber.fidento.com",
    "home_url": "http://uat.amber.infeedo.com",
    "exit_url": "",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  ai: {
    "env": "ai",
    "url": "https://api.ai.fidento.com/",
    "chat_url" : "https://chat.ai.amber.infeedo.com",
    "dashboard_url": "https://app.ai.amber.infeedo.com",
    "beta_dashboard_url": "https://beta.ai.amber.infeedo.com",
    "manager_dashboard": "https://manager.ai.amber.infeedo.com",
    "home_url": "https://ai.amber.infeedo.com",
    "exit_url": "",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  secure: {
    "env": "secure",
    "url": "https://api-us-secure.infeedo.com/",
    "dashboard_url": "http://app.secure.amber.infeedo.com",
    "beta_dashboard_url": "http://beta.secure.amber.infeedo.com",
    "manager_dashboard": "http://manager.secure.amber.infeedo.com",
    "chat_url" : "http://chat.secure.amber.infeedo.com",
    "home_url": "http://secure.amber.infeedo.com",
    "exit_url": "https://api-us-secure-exit.infeedo.com/",
    "razorpay_key": "rzp_test_PuCUN6uIq1JdwX",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },

  prod: {
    "env": "prod",
    "url": "https://api1.amber.infeedo.com/",
    "dashboard_url": "https://app.amber.infeedo.com",
    "beta_dashboard_url": "https://beta.amber.infeedo.com",
    "manager_dashboard": "https://manager.amber.infeedo.com",
    "chat_url" : "https://chat.amber.infeedo.com",
    "home_url": "https://infeedo.com",
    "exit_url": "https://api.exit.amber.infeedo.com/",
    "razorpay_key": "rzp_live_wIPJm6TNUAzk9D",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  },
  auto: {
    "env": "auto",
    "url": "https://api.automation.amber.infeedo.com/",
    "dashboard_url": "https://app.amber.infeedo.com",
    "beta_dashboard_url": "https://beta.amber.infeedo.com",
    "manager_dashboard": "https://manager.amber.infeedo.com",
    "chat_url" : "https://chat.automation.amber.infeedo.com",
    "home_url": "https://automation.amber.infeedo.com",
    // "exit_url": "https://api.exit.amber.infeedo.com/",
    // "razorpay_key": "rzp_live_wIPJm6TNUAzk9D",
    "rollout_clients": [8, 324, 355, 376, 394],
    "default_region" : "us-east-1"
  }
}

// Default to dev if not set
const config = env[process.env.GATSBY_ACTIVE_ENV];

export default {
  // Add common config values here
  ...config
};