/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {NotificationContainer} from 'react-notifications'
import "../css/bulma.css"
import "../css/index.css"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, mode, sticky_footer, no_header_margin, extra_class }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {mode ? null : <Header siteTitle={data.site.siteMetadata.title} />}
      <div className={extra_class}
        style={{
          marginTop: `${no_header_margin ? '0px' : '120px'}`,
        }}
      >
        <main>{children}</main>
        <Footer mode={mode} sticky_footer={sticky_footer} />
        <NotificationContainer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mode: PropTypes.bool.isRequired,
  sticky_footer: PropTypes.bool
}

Layout.defaultProps = {
  mode: false,
};

export default Layout
