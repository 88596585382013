import { NotificationManager } from 'react-notifications';

const handleException = (error, fallback_msg = 'Something went wrong', use_fallback_msg_as_default = false, cancel_support = null) => {
    if(cancel_support){
        return;
    }
    const error_msg = getErrorMessage(error, fallback_msg);
    NotificationManager.error((use_fallback_msg_as_default ? fallback_msg : error_msg));
}

const getErrorMessage = (error, fallback_msg) => {
    if(error.response){
        if(error.response.data){
            if(error.response.data.message){
                return error.response.data.message;
            }
            if(typeof error.response.data === 'string'){
                return error.response.data;
            }
            if(typeof error.response === 'string'){
                return error.response
            }
        }
    }
    switch(error.response.status) {
        case 429:
            return 'Too many login attempts';
        case 401:
            return 'Session Expired';
        case 500 : 
            return error.response.data.error
        default:
            return (fallback_msg.length ? fallback_msg : 'Something went wrong');
    }
}

export default handleException;