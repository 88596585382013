import axios from 'axios';
import config from '../../config';

// create new axios instance
const _axios = axios.create({
    baseURL: config.url,
});

_axios.interceptors.request.use((config_axios) => {
    const current_region_url = sessionStorage.current_region_url;
    if(current_region_url && current_region_url){
        config_axios.baseURL = current_region_url;
    } else {
        config_axios.baseURL = config.url;
    }

    
    if(sessionStorage.token){
        config_axios.headers.Authorization = `Bearer ${sessionStorage.token}`;
    }
    if(sessionStorage.role && sessionStorage.view_type) {
        config_axios.headers.role = sessionStorage.role;
        config_axios.headers['view-type'] = sessionStorage.view_type;
    }
    return config_axios;
}, (error) => {
    return Promise.reject(error);
});

_axios.interceptors.response.use((response) => {
    if(response && response.status === 201 && response.data && response.data.sso_url){ 
        window.location.href = response.data.sso_url;
    }
    else {
        return response;
    }
}, (error) => {
    return Promise.reject(error);
});
        
export default _axios;