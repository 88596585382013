import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import axios from '../../helper/Axios';
import {looseFocus} from '../../helper/LooseFocus';
import { NotificationManager } from 'react-notifications';
import ScheduleDemo from './ScheduleDemo';
import emailList from '../../helper/FreeEmailProviders';
import {resetSessionStorage} from './../../helper/getApiBase';

export default class ScheduleDemoButton extends Component {

    state = {
        
        use_form_api: true,
        is_active: false,
        email: '',
        phone: undefined,
        first_name: '',
        last_name: '',
        description: '',
        submitting: false,
        designations: [
            'C-Level/SVP',
            'VP/Director/HR Head',
            'HRBP',
            'Employee Engagement Specialist',
            'Other'
        ],
        employee_count: [
            'Less than 200',
            '200-500',
            '500-1000',
            '1000-5000',
            '5000-10000',
            '10000+'
        ],
        selected_employee_count: '',
        selected_designation: ''
    }
// fn to get the cookie value in case if formsApi
 getCookie=(name)=>
  {
    let re = new RegExp(name + "=([^;]+)");
    let value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;}
    componentDidUpdate(prevProps){
        const { trigger_tour } = this.props;
        if(prevProps.trigger_tour !== trigger_tour){
            this.setState({is_active: true});
        }
    }

    // On change input values map to state
    // -----------------------------------
    onChange = e => this.setState({ [e.target.name]: e.target.value });
    
    phoneNumberSet = (value, data, event, formattedValue) =>{
        this.setState({phone: formattedValue})};
    // open modal
    // ----------
    openModal = () => {
        if(document.getElementById("stickyTableHead")){
            document.getElementById("stickyTableHead").classList.remove('sticky');
        }
        this.setState({is_active: true});
    }
    
    // close modal
    // -----------
    closeModal = () => {
        if(document.getElementById("stickyTableHead")){
            document.getElementById("stickyTableHead").classList.add('sticky');
        }
        this.setState({is_active: false});
    }

    // on submit
    // ---------
    onSubmit = async (e) => {
        const { first_name, last_name, email, phone, description, selected_designation, selected_employee_count, use_form_api } = this.state;
        e.preventDefault();
        const domain = (email ? email.split('@')[1] : '');
        if(emailList.includes(domain)){
            NotificationManager.info("Please enter a company email.");
            return;
        }
        if(!selected_employee_count.length || selected_employee_count === 'Select Number of Employees'){
            NotificationManager.info("Please select number of employees.");
            return;
        }
        looseFocus();
        resetSessionStorage();
        this.setState({submitting: true});
        let payload={};
        try {
            if (use_form_api) {
                const path = window.location.pathname;
                const page_name = path.split("/").pop();
                const page_uri = window.location.href;
                const hutk = this.getCookie('hubspotutk');
                payload = {
                    first_name, last_name, email, phone, description,
                    designation: selected_designation, number_of_employees: selected_employee_count,
                    organic_demo: true, 
                    page_uri, 
                    hutk, 
                    page_name
                }
                await axios.post('/hubspot/form_api', payload);
            }
            else {
                payload = {
                    first_name,last_name,email,phone, description, 
                    designation: selected_designation, number_of_employees: selected_employee_count,
                    organic_demo: true
                }
                await axios.post('/hubspot/contact_us', payload);
            }
            this.setState({ first_name: '', last_name: '', email: '', phone: null, description: '', is_active: false });
            NotificationManager.success("Thank you for getting in touch");
        } catch (error) {
            if(error && error.response && error.response.status === 409){
                NotificationManager.success("Thank you for getting in touch");
                this.setState({first_name: '', last_name: '', email: '', phone: null, description: '', is_active: false});
            }
            else {
                NotificationManager.error('Something went wrong');
            }
        }
        this.setState({submitting: false});
    }

    render() {
        const { is_active, submitting, first_name, last_name, email, phone, description, designations, employee_count, selected_employee_count, selected_designation } = this.state;
        const { is_fullwidth, is_hidden_mobile, is_hidden_desktop , is_hidden_tablet, initial_email_enabled, button_text, is_small } = this.props;
        return (
            <React.Fragment>
                {initial_email_enabled ? 
                    <div className="field has-addons">
                        <div className="control">
                            <input className="input min-width-250 get-started-bar" type="text" value={email} onChange={this.onChange} name="email" placeholder="Official Email ID"/>
                        </div>
                        <div className="control">
                        <button onClick={this.openModal} className="button is-link email-button new-demo-btn">
                            Get Started
                        </button>
                        </div>
                    </div>
                : <button onClick={this.openModal} className={classnames('button is-blue', 'new-demo-btn', {'is-fullwidth': is_fullwidth, 'is-hidden-mobile': is_hidden_mobile, 'is-hidden-desktop ': is_hidden_desktop , 'is-hidden-tablet': is_hidden_tablet, 'is-small has-text-weight-bold': is_small, 'nav-button': !is_small})}>
                    {button_text}
                  </button>
                }
                <ScheduleDemo is_active={is_active} phoneNumberSet= {this.phoneNumberSet} onChange={this.onChange} onSubmit={this.onSubmit} onClose={this.closeModal} submitting={submitting} first_name={first_name} last_name={last_name} email={email} phone={phone} description={description} designations={designations} employee_count={employee_count} selected_designation={selected_designation} selected_employee_count={selected_employee_count}/>
            </React.Fragment>
        )
    }
}


// Porptypes
// ---------
ScheduleDemoButton.propTypes = {
    is_fullwidth: PropTypes.bool, 
    is_hidden_mobile: PropTypes.bool, 
    is_hidden_tablet: PropTypes.bool,
    is_hidden_desktop: PropTypes.bool,
    initial_email_enabled: PropTypes.bool,
    button_text: PropTypes.string,
    is_small: PropTypes.bool,
    trigger_tour: PropTypes.bool
};

// default props
// -------------
ScheduleDemoButton.defaultProps = {
    is_fullwidth: false, 
    is_hidden_mobile: false, 
    is_hidden_desktop : false, 
    is_hidden_tablet: false,
    initial_email_enabled: false,
    button_text: 'Schedule a Demo',
    is_small: false,
    trigger_tour: false
};
